.fj-gallery {
    position: relative;
    overflow: hidden;
    transition: .2s height ease-in-out;
}
.fj-gallery::after {
    content: "";
    display: block;
    clear: both;
}
.fj-gallery .fj-gallery-item {
    float: left;
    top: 0;
    left: 0;
    -webkit-transition: .2s -webkit-transform ease-in-out, .2s width ease-in-out, .2s height ease-in-out;
    transition: .2s transform ease-in-out, .2s width ease-in-out, .2s height ease-in-out;
}
.fj-gallery .fj-gallery-item > img {
    width: 100%;
    height: auto;
}
